<template>
  <div>
    <ItemForm
      :item-id="localItemId"
      class="py-2"
      :loading="loadingForm"
      :editing="true"
    ></ItemForm>
  </div>
</template>

<script>
import ItemForm from "@/components/areas/warehouse/items/ItemForm.vue"

export default {
  components: {
    ItemForm,
  },
  data: function () {
    return {
      loadingForm: false,
      localItemId: 0,
      localItem: [],
    };
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$store.dispatch("warehouse/items/resetFields");
    this.localItemId = Number(this.$route.params.id);
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>