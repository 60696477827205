<template>
  <div>
    <FullScreenDialog
      v-model="itemDialog"
      :title="title"
      :routeFather="pathToGoBack"
      :routeFatherName="pathName"
      :otherGoBackParams="{ params: { filters: this.filters } }"
    >
      <template v-slot:header-actions>
        <v-row class="d-flex justify-content align-center">
          <v-col cols="1" class="ml-5 mr-2">
            <v-tooltip allow-overflow top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  color="green"
                  @click="handlePermission('upload')"
                >
                  <v-icon>
                    mdi-package-up
                  </v-icon>
                </v-btn>
              </template>
              <span>Carica Prodotto</span>
            </v-tooltip>
          </v-col>
          <v-col cols="1">
            <v-tooltip allow-overflow top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  color="red"
                  @click="handlePermission('unload')"
                >
                  <v-icon>
                    mdi-package-down
                  </v-icon>
                </v-btn>
              </template>
              <span>Scarica Prodotto</span>
            </v-tooltip>
          </v-col>
          <v-col cols="9" class="d-flex align-start">
            <v-subheader>
              <v-col cols="9">
                {{ qtyLabelColor }}
              </v-col>
              <v-col cols="1">
                <v-avatar :color="qtyStatusColor" size="45"></v-avatar>
              </v-col>
            </v-subheader>
          </v-col>
        </v-row>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <SimpleList
            identifier-key="key"
            :items="rightMenu"
            @item-click="isAlert"
          ></SimpleList>
        </v-menu>
      </template>
      <template v-slot:toolbar-extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="tab in itemTabs" :key="tab.key">{{ tab.title }}</v-tab>
        </v-tabs>
      </template>
      <template v-slot:content>
        <v-tabs-items v-model="tab" :key="tab_reload">
          <v-tab-item v-for="tabItem in itemTabs" :key="tabItem.key">
            <component :is="tabItem.component"></component>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close()">Annulla</v-btn>
        <v-btn
          text
          color="default"
          v-show="isCreateRoute"
          :disabled="!formValid || isLicenseBlocked"
          @click="saveItem()"
        >
          Salva</v-btn
        >
      </template>
    </FullScreenDialog>

    <StandardDialog
      v-model="showBarcode"
      title="Carico/Scarico Magazzino"
      :dialog-height="null"
      dialog-max-width="500px"
      persistent
    >
      <ManualBarcodeInput
        v-model="barcodePermission"
        @confirm="uploadPermission"
      >
      </ManualBarcodeInput>
    </StandardDialog>

    <StandardDialog
      dialog-width="350"
      dialog-height="calc(180-10)"
      persistent
      v-model="openDialogUpdateQt"
      title="Modifica Quantità"
    >
      <v-text-field
        class="mt-8"
        v-model="qtToUpdate"
        dense
        label="Quantità"
        hide-details="auto"
      ></v-text-field>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn 
          :disabled="qtToUpdate == undefined || qtToUpdate < 1" 
          text 
          @click="updateQuantity"
          :loading="movementsLoading"
        > 
          Elabora 
        </v-btn>
        <v-btn 
          text 
          color="error" 
          @click="openDialogUpdateQt = false"
          :loading="movementsLoading"
        > 
          Chiudi 
        </v-btn>
      </template>
    </StandardDialog>

    <StandardDialog
      dialog-width="300"
      dialog-height="calc(180px-10px)"
      persistent
      v-model="dialogLeaveScreen"
      title="Attenzione"
    >
      Lasciare la pagina senza salvare?
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogLeaveScreen = false"> Rimani </v-btn>
        <v-btn text color="error" @click="handleMenuItemClick"> Esci </v-btn>
      </template>
    </StandardDialog>

    <StandardDialog
      dialog-width="300"
      dialog-height="180"
      persistent
      v-model="showErrorDialog"
      title="Errore"
    >
      {{ errorMessage }}
    </StandardDialog>
  </div>
</template>

<script>
import { SimpleList } from "likablehair-ui-components";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardDialog from "@/components/common/StandardDialog";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import ItemUpdateFormTab from "@/components/areas/warehouse/items/edit/ItemUpdateFormTab.vue";
import OrderItemsForm from "@/components/areas/warehouse/items/new/OrderItemsFormTab.vue";
import MovementsItemDetail from "@/components/areas/warehouse/items/detail/MovementsItemDetail.vue";
import itemsService from "@/services/warehouse/items.service.js";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import movementsService from "@/services/warehouse/movements.service";
import currentUser from "@/services/currentUser.service";
import operatorService from '@/services/operators/operators.service.js';

// import ExcelPreview from '@/components/common/ExcelPreview.vue'

export default {
  name: "ItemHome",
  components: {
    FullScreenDialog,
    StandardDialog,
    ManualBarcodeInput,
    SimpleList,
    ItemUpdateFormTab,
    OrderItemsForm,
    MovementsItemDetail,
    // ExcelPreview
  },
  data: function () {
    return {
      qtyStatusColor: "",
      qtyLabelColor: "",
      showErrorDialog: false,
      errorMessage: "",
      dialogLeaveScreen: false,
      tab: null,
      itemDialog: this.openDialog,
      tabComponent: undefined,
      itemTabs: this.tabs,
      newItem: undefined,
      localItemNameRoot: undefined,
      title: "",
      rightMenu: [
        {
          title: "Lista Prodotti",
          icon: "mdi-format-list-text",
          routeName: "ItemList",
        },
        {
          title: "Stampa Prodotto",
          icon: "mdi-printer",
          action: "Print",
        }
      ],
      originalUrl: window.location.pathname,
      currentItem: {},
      userId: undefined,
      openDialogUpdateQt: false,
      qtToUpdate: undefined,
      type: undefined,
      tab_reload: 0,
      barcodePermission: undefined,
      showBarcode: false,
      tempSelectedType: undefined,
      isLicenseBlocked: undefined,
      movementsLoading: false
    };
  },
  mounted() {
    
    this.get(this.$route.params.id);
    this.currentRoute = this.$route.path;
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/warehouse/items/list",
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: "warehouse",
    },
    filters: {
      type: Array,
    },
    tabs: {
      type: Array,
      default: function () {
        return [
          {
            key: 0,
            title: "Modifica Prodotto",
            icon: "mdi-book",
            name: "edit/update-tab",
            component: "ItemUpdateFormTab",
          },

          {
            key: 1,
            title: "Movimenti a Magazzino",
            icon: "mdi-book",
            name: "detail/movements-item-tab",
            component: "MovementsItemDetail",
          },
          // {
          //   key: 1,
          //   title: "Crea Ordine da Prodotti",
          //   icon: "mdi-book-plus-outline",
          //   name: "new/orders-items-tab",
          //   component: "OrderItemsForm",
          // },
          // {
          //   key: 2,
          //   title: "Test Excel",
          //   icon: "mdi-book-plus-outline",
          //   name: "new/test-excel",
          //   component: "ExcelPreview",
          // },
        ];
      },
    },
  },
  methods: {
    uploadPermission() {
      operatorService.canPerformOperation(this.barcodePermission, "Warehouse", "manage").then((result) => {
        if (result) {
          this.openDialogUpdateQt = true
          this.qtToUpdate = undefined
        } else {
          alert("Non hai i permessi per eseguire questa azione!")   
        }
      }).catch((error) => {
        console.log(error)
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false
    },
    handlePermission(type) {
      this.barcodePermission = undefined
      this.showBarcode = true
      this.type = type
    },
    setUser() {
      currentUser.getUser().then((user) => {
        this.userId = user.id;
      });
    },
    updateQuantity() {
      if (this.qtToUpdate <= 0) {
          this.$delegates.snackbar('Attenzione! Inserire un valore valido')
          this.qtToUpdate = undefined
          return
      }
      this.movementsLoading = true
      if (this.type == "upload") {
        let itemToUpload = {
          ...this.currentItem,
          "qtyToUpload": this.qtToUpdate
        }

        movementsService.uploadFromScanner([itemToUpload], this.userId).then(() => {
          this.get(this.$route.params.id);
          this.openDialogUpdateQt = false
          this.qtToUpdate = undefined
          this.tab_reload++;
          this.$delegates.snackbar('Quantità caricata correttamente')
          this.movementsLoading = false
        })
      }

      else if (this.type == "unload") {
        let itemToUnload = {
          ...this.currentItem,
          "qtyToUnload": this.qtToUpdate
        }

        movementsService.unloadFromScanner([itemToUnload], this.userId).then(() => {
          this.get(this.$route.params.id);
          this.openDialogUpdateQt = false
          this.qtToUpdate = undefined
          this.tab_reload++;
          this.$delegates.snackbar('Quantità scaricata correttamente')
          this.movementsLoading = false
        })
      }
    },
    get(id) {
      itemsService.get(id).then((item) => {
        this.title =
          "Dettaglio Prodotto " +
          item.description +
          " - " +
          item.code +
          " (" +
          item.internalCode +
          ")";
        this.currentItem = item;
        this.handleQtyAvatarColor(item);
      });
    },
    handleQtyAvatarColor(item) {
      if (item.qtyInStock == 0) {
        this.qtyLabelColor = "Non Disponibile";
        this.qtyStatusColor = "red";
      } else if (Number(item.qtyInStock) >= Number(item.qtyMinStock)) {
        this.qtyLabelColor = "Disponibile";
        this.qtyStatusColor = "success";
      } else {
        this.qtyLabelColor = "In sottoscorta";
        this.qtyStatusColor = "orange";
      }
    },
    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.itemTabs.length; i++) {
        if (this.itemTabs[i].name == tabName) {
          this.tab = i;
          break;
        }
      }
    },
    handleMenuItemClick() {
      this.dialogLeaveScreen = false;
      this.goTo(this.localItemNameRoot);
    },
    isAlert(item) {
      if(!!item.routeName)
        this.alertExitScreen(item)
      else
        this.doAnAction(item)
    },
    doAnAction(item){
      if(item.action == 'Print'){
        itemsService.printItem(this.$route.params.id)
      }
    },
    alertExitScreen(item) {
      this.localItemNameRoot = item.routeName;
      this.dialogLeaveScreen = true;
    },
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },
    close() {
      this.itemDialog = false;
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack, params: { filters: this.filters }})
    },
    saveItem() {
      switch (this.tabComponent) {
        case "ItemUpdateFormTab":
          this.saveEntity();
          break;
        case "OrderItemsForm":
          break;
        default:
          break;
      }
    },

    saveEntity() {
      let currentItemEdit = this.$store.state.warehouse.items.data.item;
      let barcodeEdit = this.$store.state.warehouse.items.data.item.barcode;
      itemsService
        .update({ item: currentItemEdit, barcode: barcodeEdit })
        .then((value) => {
          // this.$emit("reload-items");
          this.close()
        })
        .catch((err) => {
          this.errorMessage = itemVocabulary.Code_AlreadyPresent.desc;
          this.showErrorDialog = true;
        });
    },
  },
  computed: {
    formValid() {
      return this.$store.state.warehouse.items.data.valid;
    },
    isCreateRoute() {
      if (this.tabComponent === "ItemUpdateFormTab") return true;
      else return false;
    },
  },
  watch: {
    openDialog(newVal) {
      this.itemDialog = newVal;
    },
    tab(newVal) {
      this.tabComponent = this.itemTabs[newVal].component;
      try {
        window.history.pushState(
          "Tab",
          "Tab",
          this.originalUrl + "?tabName=" + this.itemTabs[newVal].name
        );
      } catch (err) {
        console.log("try setting the tab name but something goes wrong");
      }
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
</style>