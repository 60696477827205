<template>
  <div>
<!--     <AdvancedSearch
      placeholder="Cerca prodotti"
      :filters="movementListFilter"
      @apply="applyFilters"
    ></AdvancedSearch> -->
    <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
      <MovementDataTable
        :show-select="false"
        :headers="headers"
        :items="movementsItem"
        :loading="loading"
        item-key="id"
        loading-text="Caricamento movimento"
        :translator="translator"
        fixed-header
        :show-actions="false"
        :height="dataTableHeight"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
      >
        <template v-slot:custom-qty="{ item }">
          <v-chip
            class=""
            v-if="item['qty'] > 0 && (item['type'] == 'upload' || item['type'] == 'inventoryUpload')"
            color="success"
          >
            {{ item["qty"] }}</v-chip
          >
          <v-chip class="" v-else color="error">
            {{ item["qty"] ? item["qty"] : 0 }}</v-chip
          >
        </template>

        <template v-slot:custom-createdAtTime="{ item }">
          <DateTimeFormattor
            :with-time="true"
            :with-date="false"
            v-model="item['createdAt']"
            not-specified-text="Non specificato"
          ></DateTimeFormattor>
        </template>
        <!-- 
        <template v-slot:custom-qtyInStock="{ item }">
          <v-chip
            class="d-flex flex-wrap aling-center justify-center"
            v-if="item.item['qtyInStock'] > 0"
            color="success"
          >
            {{ item.item["qtyInStock"] }}</v-chip
          >
          <v-chip
            class="d-flex flex-wrap aling-center justify-center"
            v-else
            color="error"
          >
            {{ item.item["qtyInStock"] ? item.item["qtyInStock"] : 0 }}</v-chip
          >
        </template> -->

        <template v-slot:custom-type="{ item }">
          <v-icon
            class="d-flex flex-wrap aling-center justify-center"
            v-if="item['type'] === 'upload' || item['type'] === 'inventoryUpload'"
            color="success"
          >mdi-package-up</v-icon>
          <v-icon
            class="d-flex flex-wrap aling-center justify-center"
            v-else-if="item['type'] === 'unload' || item['type'] === 'inventoryUnload'"
            color="error"
          >mdi-dolly</v-icon>
        </template>
      </MovementDataTable>
    </div>
  </div>
</template>

<script>
import MovementDataTable from "@/components/common/TypeDataTable";
import movementService from "@/services/warehouse/movements.service.js";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import AdvancedSearch from "@/components/common/AdvancedSearch.vue";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";

export default {
  name: "MovementsItemDetail",
  components: {
    FullScreenDialog,
    MovementDataTable,
    DateTimeFormattor,
    AdvancedSearch,
  },
  data() {
    return {
      loading: false,
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      dataTableHeightValue: 600,
      resizeListener: undefined,
      headers: [],
      movementListFilter: [],
      movementsItem: [],
      defaultFilters: [{ itemId: Number(this.$route.params.id) }]
    };
  },
  mounted: function () {
    this.movementListFilter = [
      {
        name: "unload",
        type: "boolean",
        text: "Scarico",
        icon: "",
      },
      {
        name: "upload",
        type: "boolean",
        text: "Carico",
        icon: "",
      },
      {
        name: "createdAt",
        type: "date",
        text: "Data Creazione",
        icon: "mdi-calendar",
      },
    ]

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);
    this.fetchHeaders();
    this.fetchMovementsItem({ itemId: Number(this.$route.params.id) });
  },
  methods: {
    fetchMovementsItem(filters) {
      this.loading = true;
      movementService
        .getMovementItems(this.page, this.rowPerPage, filters)
        .then((results) => {
          if (this.rowPerPage != results.rowPerPage) {
            this.rowPerPage = results.rowPerPage;
          }

          if (this.page != results.page) {
            this.page = results.page;
          }

          this.totalPages = results.totalPages;
          if (this.totalPages < this.page) {
            this.page = this.totalPages;
          }
          this.movementsItem = results.rows;
          this.loading = false;
        });
    },
    translator(field, value) {
      if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    fetchHeaders() {
      movementService._fields_small().then((headers) => {
        this.headers = headers;
      });
    },
    applyFilters(filters) {
      this.currentFilters = filters
      this.fetchMovementsItem({...{ itemId: Number(this.$route.params.id) },...filters})
    },
  },
  computed: {
    filteredUploadMovementItems() {
      return this.movementItems;
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    page() {
      this.fetchMovementsItem({ itemId: this.$route.params.id });
    },
    rowPerPage() {
      this.fetchMovementsItem({ itemId: this.$route.params.id });
    },
  },
};
</script>

<style>
</style>